import * as React from "react"
import { useState, useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_shop.module.scss"

export default function ConfrmationPage ({location}) {
  const [submitDate, setDate] = useState("");
  const [orderNum, setNum] = useState("");
  const [contentShow, setContent] = useState(false);

  useEffect(() => {
    const parametor = location.search.replace("?" , "" );

    try {
      const decoded = decodeURIComponent(atob(parametor));

      const params = new URLSearchParams(decoded);

      const orderNumber = params.get('num')
      const orderDate = params.get('date')

      if (orderNumber !== null && orderDate !== null) {
        setNum(orderNumber)
        setDate(orderDate)
        setContent(true)
      } else {
        alert("カゴの中身がありません。")
        setContent(false)
      }
    } catch (e) {
      alert('URLが間違っています。')
      setContent(false)
    }
  }, []);

  return (
    <Layout>
      <Seo title="ご注文確定" />
      <div className={styles.shopHeader}>
        <h1>ReNK CHANNEL オンラインショッピング</h1>
      </div>
      <div className="pageWrapper">
        {contentShow === true &&
          <div>
            <h2 className={styles.confTtl}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
              ありがとうございます。注文が確定されました。
            </h2>

            <div className={styles.orderDetailWrapper}>
              <p className={styles.orderDetail}>注文番号：{orderNum}</p>
              <p className={styles.orderDetail}>受付時間：{submitDate}</p>

              <p>注文内容と振込方法をご確認のメールをお送りさせていただきます。</p>
              <p>確認メールが届かない場合は、お手数ですが
                <a href="tel:0258-25-6320">0258-25-6320</a>
                にお問い合わせ下さい。（営業時間：弊社休業日、祭日を除く月曜〜金曜の10:00〜17:00）</p>
              <p>この度はご注文ありがとうございました。またのご利用をお待ちしております。</p>
            </div>
          </div>
        }
        <div className="btnLarge">
          <a href="/" >TOPへ</a>
        </div>
      </div>
    </Layout>
  )
}
